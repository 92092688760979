<template>
  <div class="m-4 border help-document">

    <b-row class="mb-4 p-2">
      <b-col md="2">
        <div class="logo mb-1">
          <img src="../../assets/images/YT1.png" alt="" class="img-fluid" style="width: 50px; height: 50px" />
        </div>
      </b-col>
      <b-col md="10" class="text-center mt-4">
        <h2 style="font-size: 18px !important;">HELP DOCUMENT</h2>
      </b-col>
    </b-row>
    <b-col class="p-5" style="max-width: 1200px; text-align: justify; margin: 0 auto;">
      <div class="a-question">
        <b-card>
          <b-card-header>
            <button class="btn btn-link question" v-b-toggle="'faq-1'">
              1. What are the different faculty user roles and their functions in Ezygo.app?
            </button>
          </b-card-header>
          <b-collapse id="faq-1" class="m-1">
            <b-card-body>

              <p>Ezygo.app faculty user roles are designed in a hierarchical order, granting access and permissions to
                data
                based on the faculty roles' hierarchy. Depending on their roles, faculty members are responsible for
                carrying out the following functions at the beginning of every semester to ensure smooth operation:</p>


              <p class="bold underline text-primary font-13">Role 1: Admin (The Admin role can be selected from the
                'User Role' dropdown
                located in the top left
                corner, just below the navigation bar.)</p>


              <p class="bold underline text-primary font-13">Functions of Admin Role (3 Steps. Time taken to complete:
                1-10 mins.) </p>


              <p> <span class="bold underline text-danger ">Step 1:</span> Choose the <span class="bold">correct
                  academic year</span> from the dropdown provided in the
                top left below navigation bar.</p>

              <p><span class="bold underline text-danger "> Step 2 – Add Faculties:</span> Admins need to ensure that
                new faculty members have been invited
                to the institute.
                This can be accomplished by using the <span class="bold"> 'Invite Users' button</span> located at the
                top right
                corner. Please note
                that classes/courses can be assigned to faculties as soon as they are invited, and there is no need to
                wait for the faculties to complete their enrollment. Once faculties complete their enrollment, they will
                be able to see the updated information in their login.
                Additionally, admins should deactivate any faculty members who have left the institution from the <span
                  class="bold">
                  'Staff,
                  Student List' tab</span> to ensure data security.</p>

              <p> <span class="bold underline text-danger "> Step 3 – Add/Update HODs to Departments:</span> Admins are
                also responsible for verifying that
                departments have
                been created under the <span class="bold"> 'Department/Program' tab</span> and that Head of Departments
                (HODs) have
                been assigned to
                each department. HODs' names should be updated for each department whenever there is a change in the HOD
                list.</p>


              <p class="bold underline text-primary font-13">Role 2: HOD (HODs, Program Coordinators,
                Class-In-Charge/Faculty Advisors, and Course Instructors have
                to
                select the 'Faculty' User Role from the dropdown located in the top left corner, just below the
                navigation
                bar.)</p>

              <p class="bold underline text-primary font-13">Functions of HOD & Program Coordinator Role (4 Steps. Time
                taken to complete: 5-15 minutes)</p>

              <p><span class="bold underline text-danger "> Step 1:</span> Choose the <span class="bold"> correct
                  academic
                  year</span> from the dropdown provided in the
                top left below navigation bar.</p>

              <p><span class="bold underline text-danger "> Step 2 – Add/Verify Program Data:</span> The functions of
                HODs and Program Coordinators are
                mostly similar, with
                the key difference being that programs can only be created by HODs. Programs are added to the department
                from the <span class="bold"> 'Department/Program' tab</span> by clicking on <span class="bold"> ‘Show
                  Program’ </span> in
                Department list and then clicking on
                <span class="bold"> ‘Add Program’ button.</span> At most institutions, HODs also perform the functions
                of program
                coordinators. Once
                a program coordinator has been assigned to a program, they can also perform the following functions.
                HODs/PCs must ensure that the <span class="bold"> affiliated university name, scheme, POs, and
                  PSOs</span> are
                updated in the
                program. A program should be created only once and should not be recreated unless there is a change in
                the
                scheme. Until the scheme changes, classes can be added to the same program every semester.
              </p>

              <p><span class="bold underline text-danger "> Step 3 – Create Classes:</span> HODs/PCs need to create
                classes to every program at the
                beginning of every
                semester. Classes can be created from the <span class="bold"> ‘Classes/Courses’ </span> tab by clicking
                on the <span class="bold">
                  ‘Add
                  Semester/Interdepartmental Course Group’</span> button.
                There are three different options for creating classes.
                First Option (Most Recommended): The first option is to directly pick the class from University
                Resources,
                which will automatically create class to the program, and update all the courses and course details like
                course syllabus, COs, POs, lesson plans, and more to the class. We recommend using the first option if
                no
                course details were updated in the previous academic year.</p>

              <p>Second Option: The second option is to duplicate a class/semester from the previous or current academic
                year, which will include all the data already entered, such as courses, course syllabus, COs, POs,
                lesson
                plans, and more. This option should only be used if all course data were updated in the previous
                academic
                year.</p>

              <p>Third Option: The third option is to manually create a new class/semester/interdepartmental course
                group.
                This option is the least recommended and should only be used if:
              <ol type="i">
                <li>You cannot find your University Resources in Ezygo.app.
                </li>
                <li>You have not updated course details in previous academic year.</li>
              </ol>
              </p>

              <p class="bold">In all the above options, please make sure only odd semester classes are created for odd
                academic year
                and
                only even semester classes are created for even academic year.</p>

              <p><span class="bold underline text-danger "> Step 4 – Assign Class-In-Charge/Faculty Advisor:</span>
                HODs/PCs should assign a
                Class-In-Charge/Faculty Advisor
                to each newly created class. This can be done in the <span class="bold"> ‘Classes/Courses’ tab</span> by
                clicking on
                the link
                provided below <span class="bold"> ‘Faculty Advisor List’</span> against each class.</p>

              <p class="bold underline text-primary font-13">Role 3: Faculty Advisor (Faculty Advisors need to make sure
                that the 'Faculty' User Role is selected in
                the dropdown located in the top left corner, just below the navigation bar.)</p>

              <p class="bold underline text-primary font-13">Functions of Class-In-Charge/Faculty Advisor Role (4 Steps.
                Time taken to complete: 20-45 mins.)</p>


              <p><span class="bold underline text-danger "> Step 1:</span> Choose the <span class="bold"> correct
                  academic year </span>
                from the
                dropdown provided in the top left below
                navigation bar.</p>
              <p> <span class="bold underline text-danger ">Step 2 – Course Faculty Mapping:</span> From the<span
                  class="bold">
                  ‘Classes/Courses’ tab</span> , Faculty Advisors should
                expand the
                ‘Course List’ against the respective class. All courses will be displayed here. If necessary, faculty
                advisors can review the course list and make any required modifications. Faculty advisors may also add
                courses to the class if required by clicking on the <span class="bold"> ‘Add’</span> button located just
                above the
                course list. We
                recommend you use the: <span class="bold"> ‘Copy from University Resources Option’</span> to easily
                create courses
                and automatically
                update all the course details such as course syllabus, COs, POs, lesson plans, and more updated
                automatically. You can also copy courses from the previous academic year if all course details were
                updated at that time. If neither of these options work for you, you may proceed with adding courses
                manually.</p>
              <p>Once all courses are created, please update course instructor names against each course by clicking on
                the
                link provided below <span class="bold"> ‘Instructor Details’</span> against each respective course. In
                the class
                list, you will find
                a class report icon against each class, which provides access to all the class reports and an option to
                download them.</p>
              <p><span class="bold underline text-danger "> Step 3 – Update Class Time Table:</span> Proceed to the
                <span class="bold"> ‘Time Table’ tab</span> and start
                updating the timetable for
                your class. You may create multiple timetables for your class by providing a date range for each time
                table. This allows you to change time tables in between a semester. In case of conflicting date ranges
                for
                two timetables, the time table with the highest priority will be chosen to create the schedule for the
                conflicting dates. The class schedule gets automatically updated as per the created time tables and will
                be shown below the ‘timetable’ list. Please note that already marked attendance will not be affected by
                any change in the timetable.
              </p>

              <p>To add a new timetable, click on the <span class="bold"> ‘Add New Time Table’</span> button, fill out
                the form and
                proceed to update
                the time table slots. All course-faculty combinations will appear above the blank timetable. You can
                drag
                and drop the course-faculty combinations into their respective slots. If you have multiple courses in
                the
                same slot, you can drag and drop both course-faculty combinations to the same slot. Once all the slots
                are
                updated, you can save the time table.</p>

              <p><span class="bold underline text-danger "> Step 4 – Add Students:</span> Navigate to the <span
                  class="bold">
                  ‘Classes/Courses’ tab</span> , and click on
                ‘Enroll Students’ against the
                corresponding class. Students can be added to a class in two ways:
              <ol type="a">
                <li> If the student list is already present in the previous semester, click on the <span class="bold">
                    ‘Transfer/Promote’ sub-tab</span> ,
                  and proceed with copying the student list from the previous semester.</li>
                <li> In case of new students, click on the <span class="bold"> ‘Invite Students’ sub-tab</span> . The
                  best way to
                  invite students is by
                  preparing an Excel sheet with three columns: Student Roll Number, student name and student email
                  address.
                  We highly recommend using email addresses when inviting users. Copy and paste the data into the
                  respective
                  fields as per the instructions provided. Once done, click on the ‘Proceed’ button. Verify the data in
                  the
                  student list that appears below and click on ‘Send Enrolment Code’. An invitation mail will be sent to
                  all
                  students, allowing them to complete their enrolment to the institution. Proceed to the <span
                    class="bold">
                    ‘Enrolled Student
                    List’ sub-tab</span> to view the updated student list. Please note that faculties do not need to
                  wait for student
                  enrolment to complete to start updating student data such as attendance and marks. As and when a
                  student
                  completes their enrolment, all updated data will be available in the student login.</li>
              </ol>
              </p>

              <p><span class="bold underline text-danger "> Step 5 – Update University Exam Results:</span> University
                Exams can be created by Faculty
                Advisors from
                <span class="bold"> ‘University Exam’ tab</span> . Click on the ‘Update Marks’ button against the
                University Exam to
                update University
                Exam marks. In case CO mapping is required for university exams, we recommend updating the marks by
                respective course instructors.
              </p>


              <p class="bold underline text-primary font-13"> Role 4: Course Instructor (Course Instructors need to make
                sure that the 'Faculty' User Role is
                selected
                in the dropdown located in the top left corner, just below the navigation bar.)</p>
              <p class="bold underline text-primary font-13">Functions of Course Instructor Role</p>


              <p><span class="bold underline text-danger "> Step 1:</span> Choose the <span class="bold"> correct
                  academic
                  year</span> from the dropdown provided in the
                top left below navigation bar.</p>
              <p><span class="bold underline text-danger "> Step 2 – Add/Verify Course Details:</span> The <span
                  class="bold"> 'My
                  Courses' tab</span> will display all
                the courses assigned to you.
                Click on any course to view or edit course details such as the syllabus, lesson plan, course outcomes,
                CO-PO/PSO mapping, student grouping, and more. In the course list, you will find a course report icon
                against each course, which provides access to all the course reports and an option to download them.</p>

              <p><span class="bold underline text-danger "> Step 3 - Attendance:</span> To begin marking attendance,
                click on the <span class="bold"> 'Attendance'
                  tab</span> . Your schedule for the
                week will be displayed here. Click on the course code listed in the schedule to mark attendance for the
                respective course. Options for editing/deleting attendance, marking attendance using the date picker,
                and
                downloading the schedule are available just above the schedule.</p>

              <p><span class="bold underline text-danger "> Step 4 – Assignments and Internal Tests:</span> The <span
                  class="bold">
                  'Add'</span> button in the <span class="bold">
                  'Activity' tab</span> allows you to create
                activities such as 'Internal Tests/Assessments' and 'Assignments.' You can choose between online and
                offline options when creating an activity, depending on your needs. Next to each activity, you'll find
                the
                <span class="bold"> 'Create Question Paper'</span> option, which lets you create question papers with
                'OR' and
                'Sub-question'
                grouping. You can also set the maximum marks for each question and map them to Course Outcomes (CO),
                Modules, and Bloom's Taxonomy Levels. The <span class="bold"> 'Update Mark'</span> option, located next
                to 'Create
                Question Paper,'
                will display a mark entry form based on the format set in the question paper. To select your preferred
                mark entry option, you can use the <span class="bold"> 'Choose Evaluation Options'</span> button at the
                top left of
                the mark entry
                page. You can choose between updating question-wise scores for each student with CO mapping or updating
                CO-wise scores for each student.
              </p>

              <p><span class="bold underline text-danger "> Step 5 – Course Exit Survey:</span> You can create course
                exit surveys for all your courses
                using the <span class="bold"> 'Survey'
                  tab.</span> Questions, along with options and scores for each option, can be added to each survey, and
                you can
                also map each question to Course Outcomes (CO). Students can access and complete surveys from their
                login.
                The survey report can be accessed any time using the <span class="bold"> ‘Report’ icon</span> present
                against each
                survey.</p>



            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <div class="a-question">
        <b-card>
          <b-card-header>
            <button class="btn btn-link question" v-b-toggle="'faq-2'">
              2. What are the different reports available in ezygo.app?

            </button>
          </b-card-header>
          <b-collapse id="faq-2" class="m-1">
            <b-card-body>

              <p>Ezygo.app reports are available in a standard PDF format and can be used for all internal audits,
                university audits, NBA, and NAAC. The reports are mainly classified into:</p>
              <p class="bold underline text-primary font-13">Course Reports (Reports for Course File): 26 Reports</p>

              <p>These reports include: Instructor Schedule, Course Syllabus, Course Outcome(CO), Program Outcome(PO),
                Program Specific Outcome(PSO), CO/PO/PSO Mapping, Lesson Plan, Student Roll List, Details Of Assignment,
                Details Of Tutorials (Report Contains Attendance Linked Tutorials And Activity Tutorials Separately),
                Tutorial List Log Register, Assessment Instruments & COs, Details Of Learning Material Provided, Content
                Beyond The Syllabus, Industrial Relevance Of Course, Details Of Extra Class, Details Of Remedial Class,
                Remedial/ Bridge Class Log Register, Attendance Reports, <span class="italic"> Lab Experiments, Lab
                  Evaluation Sheet,
                  Lab
                  Summary Report</span> , Course Coverage Report, Internal Evaluation Report, Attainment Reports and
                Instructor
                Feedback Survey Reports.</p>
              <p> All of these reports can be organized in the required format and downloaded as a single file to create
                the
                course diary or course file. To access your course reports, you can click on the <span class="bold"> 'My
                  Courses'
                  Tab</span> and then
                click on the <span class="bold"> ‘report’ icon</span> against each course. For faculty advisors/HODs,
                course files
                for your
                class/program can be accessed from the <span class="bold"> 'Classes (Semester)/Courses' tab</span> .
                Expand the
                courses for the
                required class, and then click on the <span class="bold"> ‘report’ icon</span> against the course.</p>

              <p class="bold underline text-primary font-13">Class Reports: 8 Reports including Batch PO Attainment
                Reports</p>
              <p>These reports include: Students Individual Activity Report, Student Roll List, University Exams Result
                Analysis, Students Date-wise Attendance Report, PO Attainment, Consolidated Attendance Statement,
                Consolidated Statement of Internal Marks, Statement of Internal Assessment Marks with Result Analysis.
              </p>

              <p>To access your class reports, you can click on the <span class="bold"> 'Classes (Semester)/Courses'
                  Tab</span> and
                then click on the
                <span class="bold"> ‘Report’ icon</span> against the Class.
              </p>

              <p class="bold underline text-primary font-13">Survey Reports: 3 Surveys</p>

              <p>Surveys include: Course Exit, Program Exit and Instructor Feedback Survey. Course exit surveys can be
                created by respective course instructors from ‘Survey’ Tab by clicking the ‘Add’ Button. Program exit
                surveys can be created by HODs from ‘Survey’ Tab also by clicking the ‘Add’ Button.</p>
              <p>Instructor Feedback Survey can only be created by Institute Admin by switching to ‘Admin’ Role and from
                ‘Survey’ Tab.</p>
              <p>Exit Survey reports can be accessed from <span class="bold"> ‘Survey’ Tab</span> by clicking on the
                <span class="bold"> ‘Report’
                  icon</span> against the
                respective survey. Instructor feedback survey reports can be accessed by Institute Admins from <span
                  class="bold">
                  ‘Report’</span>
                Tab.
              </p>


            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <div class="a-question">
        <b-card>
          <b-card-header>
            <button class="btn btn-link question" v-b-toggle="'faq-3'">
              3. How are CO and PO attainment reports for a course generated in ezygo.app?

            </button>
          </b-card-header>
          <b-collapse id="faq-3" class="m-1">
            <b-card-body>

              <p>CO and PO attainment calculation for a course consists of two components – Direct and Indirect. The
                Direct
                component for Course CO attainment calculation is comprised of Assignments, Internal Tests (Assessments)
                and University Exam, while the Indirect component for Course CO attainment calculation is comprised of
                Course Exit Survey.</p>

              <p class="bold underline text-primary font-13">CO and PO attainment report for each course is generated
                separately by respective course instructors.
              </p>

              <p>Before proceeding with generating CO and PO attainment reports for your course, please make sure you
                have
                completed the following tasks in ezygo.app:</p>

              <ol type="a">
                <li>
                  <span class="bold underline text-danger ">COs and CO-PO/PSO Mapping:</span> Verify that COs and
                  CO-PO/PSO mapping for the course has been updated. This
                  can
                  be done in ‘My Courses’ Tab > Click on the course name. Go to ‘Course Outcome’ and ‘CO-PO/PSO Mapping’
                  tabs on the left and check if the data has been updated. If PO or PSO data is missing in CO-PO/PSO
                  mapping, ask the HOD to update the data. HOD can update these details from ‘Department/Program’ Tab >
                  Expand the Program List against Department > Update PO and PSO by clicking on the links given against
                  the
                  program.
                </li>
                <li>
                  <span class="bold underline text-danger "> Assignments & Internal Tests:</span> Make sure you have
                  created assignments and internal tests (assessments)
                  for
                  the course and have updated marks with CO mapping. This can be done in ‘Activities’ Tab.
                </li>
                <li>
                  <span class="bold underline text-danger "> University Exam Results:</span> Confirm university exam
                  marks have been updated in ‘University Exam’ Tab.
                  University Exams can be created by faculty advisor for their class and can also update marks. In case
                  CO
                  mapping is required for university exams, we recommend updating the marks by respective course
                  instructors. Course instructors can update university exam marks with CO mapping by going to ‘My
                  Courses’
                  Tab > Click on the course name > University Exam > Scroll down to view the exam name > Click on
                  ‘Update
                  Marks’ button.
                </li>
                <li>
                  <span class="bold underline text-danger "> Course Exit Survey:</span> Ensure you have conducted course
                  exit survey for the specific course and students
                  have
                  responded to the survey. This can be done in ‘Survey’ tab by respective faculty.
                </li>
              </ol>


              <p>Now, to start with CO and PO attainment report generation for a course, go to ‘My Courses’ Tab, then
                click
                on the ‘Report’ icon against the course. Scroll down to ‘Attainment Report’ and click on ‘Configure and
                Download’. A progressive window opens, which takes you through each component of CO Attainment
                calculation. Click on ‘Next’ button at the top right of the pop-up window to navigate through each
                component:</p>
              <ul>
                <li>It starts with course outcome list and then proceeds to course student list.</li>
                <li>The third section (Attainment) will give you the option to customize how course CO attainment is
                  calculated by setting percentage weightage splits for both Direct and Indirect Components. In
                  addition,
                  there is an option to further specify the percentage weightage split for specific parameters within
                  the
                  Direct Components, such as Assignments, Internal Tests (Assessments), and University Exams. This
                  flexibility in setting weightages enables educators to fine-tune the evaluation process to align with
                  the
                  specific requirements and priorities of their institution/courses.</li>
                <li>It also allows to map the percentage count of students attaining target percentage to attainment
                  values.
                  This has to be done separately for direct component in ‘Attainment’ section and for course exit survey
                  in
                  ‘Survey’ section.</li>
                <li>To set target values for each direct component parameter, click ‘Next’ to proceed to each direct
                  component
                  parameter and set the target values there.</li>
                <li>In the CO-PO/PSO mapping section, select all POs and PSOs that are related to the COs of the course.
                  This
                  is done to calculate PO attainment values of the course.</li>
                <li>Finally, click on ‘Next’ to proceed to Final Attainment Report.</li>
                <li>At the bottom of ‘Attainment Report’ section, all reports are available in PDF format and can be
                  downloaded separately.</li>
              </ul>


            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <div class="a-question">
        <b-card>
          <b-card-header>
            <button class="btn btn-link question" v-b-toggle="'faq-4'">
              4. How is PO attainment report for a program batch generated in ezygo.app?

            </button>
          </b-card-header>
          <b-collapse id="faq-4" class="m-1">
            <b-card-body>

              <p>PO attainment calculation for a batch consists of two components – Direct and Indirect. The Direct
                component for batch PO attainment calculation is obtained directly from Course CO attainment of all
                courses attended by the batch students. The Indirect component for batch PO attainment calculation is
                comprised of Program Exit Survey.</p>

              <p class="bold underline text-primary font-13">PO attainment report for a batch is generated by Faculty
                Advisor/HOD.</p>

              <p>Before proceeding with PO attainment report generation for a batch, please make sure the following
                tasks
                have been completed:</p>
              <ol type="a">
                <li>
                  The CO and PO attainment calculation for all courses attended by the batch has been completed by the
                  respective faculties.</li>
                <li><span class="bold"> Program Exit Survey:</span> Ensure you have conducted program exit survey for
                  the batch and
                  students have
                  responded to the survey. This can be done in ‘Survey’ tab by HOD.</li>
              </ol>

              <p> Now, to start with batch PO attainment report generation for a course, go to ‘Classes
                (Semester)/Courses’
                Tab, then click on the ‘Report’ icon against the class. Click on the ‘Download’ button against ‘PO
                Attainment’. A progressive window opens, which takes you through each component of PO Attainment
                calculation.</p>
              <ul>
                <li>
                  Start with selecting the academic year and choosing the classes of the batch whose batch PO attainment
                  is
                  to be calculated. Click ‘Proceed’.</li>
                <li>All the courses attended by the batch is listed. Select courses and ‘Proceed’.</li>
                <li>Select all relevant POs and PSOs and ‘Proceed’ to ‘Exit Survey’</li>
                <li>Choose the Program Exit Survey conducted for the batch and ‘Proceed’ to ‘Attainment’.</li>
                <li> Customize the values for ‘Direct’ component and ‘Indirect’ component percentage weightage split and
                  ‘Proceed’.</li>
                <li>The final batch PO attainment values will be displayed. Scroll down to view and download all reports
                  in
                  PDF format.</li>
              </ul>


            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <div class="a-question">
        <b-card>
          <b-card-header>
            <button class="btn btn-link question" v-b-toggle="'faq-5'">
              5. How can I generate question paper, scheme of evaluation, and scrutiny form in ezygo.app?

            </button>
          </b-card-header>
          <b-collapse id="faq-5" class="m-1">
            <b-card-body>

              <p>Question paper, scheme of evaluation and scrutiny form are generated for an activity like assignment or
                internal test (assessment).</p>
              <p> To create an activity in ezygo.app, go to ‘Activity’ Tab, click on ‘Add’ button, and fill out the
                form.
                Choose the activity type as Assignment, Assessment (Internal Test) or Tutorial as per your requirement.
                Submit the form. The newly created activity will be listed under ‘Activity’ Tab.</p>

              <p class="bold underline text-primary font-13">Creating Question Paper, Scheme of Evaluation and Scrutiny
                Form</p>

              <ul>
                <li>Click on ‘Create Question Paper’ next to Activity name. It is recommended to create question paper
                  in a
                  2-step process.</li>
                <li><span class="bold underline text-danger "> Step 1 - Set Question Paper Format:</span> This involves
                  creating sections, creating question fields for
                  main
                  questions, sub-questions, and optional (OR) questions, grouping them as needed. For OR question
                  groupings,
                  instructors can specify the minimum number of questions a student should answer. Once this format is
                  established, instructors can proceed to the next step.</li>
                <li>
                  <span class="bold underline text-danger "> Step 2 – Add Questions and Details:</span> By clicking on
                  the 'Edit icon' in each question field, instructors
                  can
                  add question numbers, maximum marks, and the actual question text. They can also include question
                  text,
                  equations, and images as needed. Additionally, instructors have the option to map the question to
                  Course
                  Outcomes (CO), POs and PSOs, Module, and Blooms Taxonomy Levels if required. An option to add the
                  scheme
                  of evaluation for the particular question is also available.
                </li>
              </ul>
              <p>A live visualization of the actual question paper will be displayed on the right side of the screen as
                questions are entered. This allows instructors to preview the question paper's appearance in real-time.
                Once the question paper is complete, it can be printed. The question paper can be viewed/printed by
                clicking on the ‘Print/Save PDF’ button present above the Question paper preview.</p>
              <p>The ‘Report’ icon available against the activity list will give options to download:</p>
              <ul>
                <li class="bold">Question Paper</li>
                <li class="bold">Scheme of Evaluation, and</li>
                <li class="bold">Scrutiny Form</li>
              </ul>
              <p>To update marks, click on the ‘Update Marks’ option against the activity.</p>



            </b-card-body>
          </b-collapse>
        </b-card>
      </div>


      <div class="a-question">
        <b-card>
          <b-card-header>
            <button class="btn btn-link question" v-b-toggle="'faq-6'">
              6. How do I manage interdepartmental courses in ezygo.app?

            </button>
          </b-card-header>
          <b-collapse id="faq-6" class="m-1">
            <b-card-body>
              <p>Interdepartmental courses are offered by one department, and students from other departments or
                programs
                enroll in them. Examples of such courses include minors and open electives.</p>
              <p class="bold underline text-primary font-13">Adding Interdepartmental Courses from ‘University
                Resources’</p>
              <p>Interdepartmental courses like minors/open electives should be added to the department offering the
                course. These courses can be added from ‘University Resources’ by the HOD. HODs while adding classes
                from
                university resources at the beginning of a semester will also find ‘INTERDEPARTMENTAL COURSES’ in the
                class list. HODs may select ‘INTERDEPARTMENTAL COURSES’ and select the required interdepartmental
                courses
                from the list and click on <span class="bold"> ‘Start Copying’ Button</span> to complete adding the
                classes and
                courses to the new
                semester.</p>

              <p class="bold underline text-primary font-13">Adding Instructors and Students for Interdepartmental
                Courses</p>
              <p>Please note that course instructors for the interdepartmental courses should be added by the HOD of the
                department offering the course. From the <span class="bold"> ‘Classes/Courses’ tab</span> , HODs should
                expand the
                <span class="bold"> ‘Course List’</span>
                against ‘Interdepartmental course (IDC)’. All interdepartmental courses will be displayed here. Please
                update course instructor names against each course by clicking on the link provided below <span
                  class="bold">
                  ‘Instructor
                  Details’</span> against each respective course.
              </p>
              <p>Students can be added to these courses from other departments either by the Head of the Department
                (HOD)
                using the 'Classes/Courses' tab or by the course instructor using the 'My Courses' tab. To add students,
                click on the 'Enroll Students' option next to the course and proceed to add them to the course.</p>

            </b-card-body>
          </b-collapse>
        </b-card>
      </div>



    </b-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style  scoped>
.help-document li {
  margin-top: 5px;
}
.bold {
  font-weight: bold;
}

.font-13 {
  font-size: 13px !important;
}

.underline {
  text-decoration: underline;
}

.help-document {
  background-color: white;
}

.card {
  border: none;
  font-size: 14px !important;
}

.card-header {
  background-color: white;
}

.card-body {
  padding-bottom: 2px;
}
</style>
